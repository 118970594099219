import React, { useState } from 'react'
import axios from 'axios';
import Sidebarmenu from '../components/Sidebarmenu'
export default function Budgetinglist(props) {
  const apidata = props.apidata;
  const budgetlist =apidata.products['personal-budgeting-service'].list.data;

  const [loading, setloading]= useState(false);
  const [success, setsuccess]= useState(false);


  const token = JSON.parse(localStorage.getItem('login'));


  
  const [id, setid]= useState(null);

  const [provider, setprovider]= useState(null);
  const [cost, setcost]= useState('');
  const [traff, settraff]= useState('');
  const [savings, setsavings]= useState('');
  const [renewal, setrenewal]= useState('');

  console.log(id);  

    let updateset = budgetlist.find(o => o.id === id);
   
 
 
  

  const config = {
    headers: { Authorization: `Bearer ${token.token}` }
  };

  const Updatebudgeting = () =>{     
    console.log('data updating');  
    if(id!=null)
    {   
        setloading(true);
        console.log('saving updating');  
        axios.post('https://clearstart-api.clientmaster.co.uk/api/portal/client/change-request/personal-budgeting-service',{id,provider,cost,traff,savings,renewal}).then((response) =>{   
            let userdata = JSON.stringify(response.data);       
            console.log(userdata);
            setsuccess(true);
            setloading(false);
        }).catch((error) => {  
            setloading(false);
            setsuccess(false);
          });       
    }
   
  }

  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Budgeting List</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Manage Your Budget</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>  


        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
              <button type="button" style={{float:'right',}} className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">
               Update Budget
              </button>
                <h4 className="card-header-title">Budgeting List</h4>
                
       
              </div>             
             


                    
              <div className="table-responsive">
                <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle">
                  <thead className="thead-light">
                    <tr>
                      <th>Type</th>
                      <th>Provider</th>
                      <th>Current Cost</th>
                      <th>New Traff</th>
                      <th>Savings</th>
                      <th>Renewal Date</th>
                    </tr>
                  </thead>

                  <tbody>
                  {budgetlist.map(data => {                        
                                    return (
                                  
                    <tr  key={data.id}>
                        {data.cost? <>
                      <td><a href="#">{data.type}</a></td>
                      <td>{data.provider? data.provider : 'N/A' }  </td>
                      <td>{data.provider? '£ ' +  data.cost : 'N/A' }</td>
                      <td>{data.provider? '£ ' +  data.traff : 'N/A' }</td>
                      <td>{data.provider? '£ ' +  data.savings : 'N/A' }</td>
                      <td>{data.provider? data.renewal : 'N/A' } </td>
                      </> : <></> }
                    </tr> 

              )
              })} 

                  

                  
                  </tbody>
                </table>
              </div>
              




              </div>

            
          </div>
        </div> 
      </div>

    </div>     

    <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Update Budgeting</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        {success?<>
          <div className="alert alert-success" role="alert">
          Your Request as been sent to our agents please allow upto 2 working Days to see changes on Your account.
                </div>
                </>:<></>}  
          <div className="mb-3">
            <label className="form-label" for="exampleFormControlSelect1">Select Type</label>
            <select id="exampleFormControlSelect1" onChange={(e)=> setid(e.target.value)}  className="form-control">
              <option value='0'>Choose an option</option>
              {budgetlist.map(data => {                        
                return (
              <option key={data.id} value={data.id}>{data.type}</option>
              )
            })} 
           
            </select>
         </div>
       	{id? <>
        <div className="mb-3">
          <label className="form-label" >Provider</label>
          <input type="text" onChange={(e)=> setprovider(e.target.value)} value={provider?provider:updateset.provider?updateset.provider:''} className="form-control" placeholder="Provider"/>
        </div>
        <div className="mb-3">
          <label className="form-label" >Current Cost</label>
          <input type="text" onChange={(e)=> setcost(e.target.value)} value={cost?cost:updateset.cost?updateset.cost:''} className="form-control" placeholder="Current Cost"/>
        </div>
        <div className="mb-3">
          <label className="form-label" >New Traff</label>
          <input type="text" onChange={(e)=> settraff(e.target.value)}  value={traff?traff:updateset.traff?updateset.traff:''} className="form-control" placeholder="New Traff"/>
        </div>
        <div className="mb-3">
          <label className="form-label" >Savings</label>
          <input type="text" onChange={(e)=> setsavings(e.target.value)} value={savings?savings:updateset.savings?updateset.savings:''} className="form-control" placeholder="Savings"/>
        </div>
        <div className="mb-3">
          <label className="form-label" >Renewal Date</label>
          <input type="date" onChange={(e)=> setrenewal(e.target.value)} value={renewal?renewal:updateset.renewal?updateset.renewal:''} className="form-control" placeholder="Renewal Date"/>
        </div>
        </>:<></>}

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-white" data-bs-dismiss="modal">Close</button>
        <button type="button" onClick={Updatebudgeting} className="btn btn-success" disabled={loading}>{loading?'Please Wait':'Save Changes'}</button>
      </div>
    </div>
  </div>
</div>

     
  </div>
  )
}
