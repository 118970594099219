import React, {useState, useEffect} from 'react'
import axios from 'axios';
import Sidebarmenu from '../components/Sidebarmenu'
import { useNavigate, Link  } from 'react-router-dom';
export default function Changepassword(props) {
  const apidata = props.apidata;
  const userid = apidata.Client.id;

  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem('login'));



  const [loading, setloading]= useState(false);
  const [success, setsuccess]= useState(false);
  const [mismatch, setmismatch]= useState(false);
  
  const [password, setnewpassword]= useState(null);
  const [retype_password, setrenewpassword]= useState(null);

  const config = {
    headers: { Authorization: `Bearer ${token.token}` }
  };

  const Changepasswords = () =>{     
    console.log('change passwords');  
    if(password===retype_password)
    {   
        setloading(true);
        setmismatch(false);
        axios.post('https://clearstart-api.clientmaster.co.uk/api/portal/client/change-password/',{password,retype_password}).then((response) =>{   
            let userdata = JSON.stringify(response.data);       
            console.log(userdata);
            setsuccess(true);
            setloading(false);
        }).catch((error) => {  
            setloading(false);
            setsuccess(false);
            alert('session timeout please re-login and try again')
                console.log('login timeout');
                localStorage.clear();      
                navigate('/login')  
           
          });       
    }
    else
    {
        //password missmatch
        setmismatch(true);
    }

  }



  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Change Password</h1>
                </div>
    
            
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Secure Your Account </li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>  


            <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Change Password
                </h4>
              </div>             
              <div className="card-body">

              {success? <>
                <div className="alert alert-success" role="alert">
                 Password has been updated
                </div>
                </>: <></>}

                {mismatch? <>
                <div className="alert alert-warning" role="alert">
                Passwords not matching.  please try again!
                </div>
                </>: <></>}

                
                <div className='row'>
                    <div className='col-md-6'>
                        <label className="form-label" for="exampleFormControlInput1">Enter New Password</label>
                        <input className="form-control"  onChange={(e)=> setnewpassword(e.target.value)}  type="text" placeholder="Enter New Password"/>
                    </div>
                    <div className='col-md-6'>
                         <label className="form-label" for="exampleFormControlInput1">Re-enter New Password</label>
                        <input className="form-control"  onChange={(e)=> setrenewpassword(e.target.value)}  type="text" placeholder="Re-enter New Password"/>
                    </div>
                
                </div>
                <button type="button" onClick={Changepasswords} class="btn btn-success" style={{float:'right',marginTop:'15px',}}disabled={loading}>{loading?'Please Wait':'Change Password'}</button>




              </div>

            </div> 
        
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
